<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiPipeGasketId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <!-- <div class="col-4">
                    <c-moc 
                      :editable="editable && isRevision"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="popupParam.psiPipeGasketId"
                      documentTitle="pipeName"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div> -->
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 분류별 유체목록 -->
          <c-table
            ref="table"
            title="분류별 유체목록"
            tableId="table"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="false"
            :columns="grid.columns"
            :data="grid.data"
            @rowClick="rowClick"
            gridHeight="315px"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <!-- 배관 및 개스킷 상세정보 -->
          <c-card title="배관 및 개스킷 상세정보" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiPipeGasketId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="psiPipeGasketId"
                name="selectedpsiPipeGasketId"
                label=""
                v-model="selectedpsiPipeGasketId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{ $language('(주)') }}
                    </div>
                    <div class="tooltipCustom">
                    <!-- ① 분류코드란에는 공정배관계장도 도면상의 배관분류 코드를 기재합니다. -->
                    {{ $language('① 분류코드란에는 공정배관계장도 도면상의 배관분류 코드를 기재합니다.') }}<br>
                    <!-- ② 배관재질란은 KS/ASTM 등의 기호로 기재합니다. -->
                    {{ $language('② 배관재질란은 KS/ASTM 등의 기호로 기재합니다.') }}<br>
                    <!-- ③ 개스킷 재질 및 형태란에는 일반명 및 형태를 기입하고 상품번호는 기재하지 아니합니다. -->
                    {{ $language('③ 개스킷 재질 및 형태란에는 일반명 및 형태를 기입하고 상품번호는 기재하지 아니합니다.') }}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 유체정보 개정 -->
                <c-btn
                  v-show="editable && popupParam.psiPipeGasketId && !isRevision"
                  label="유체정보 개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="editable && popupParam.psiPipeGasketId && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 유체 추가 -->
                <c-btn
                  v-if="editable && popupParam.psiPipeGasketId"
                  label="유체 추가"
                  icon="add"
                  @btnClicked="addData"/>
                <!-- 유체 삭제 -->
                <c-btn
                  v-if="editable && popupParam.psiPipeGasketId && deletable"
                  label="유체 삭제"
                  icon="remove"
                  @btnClicked="deleteData"/>
                      <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <!-- 분류코드 -->
                <c-text
                  :required="true"
                  :editable="editable && pipeTypeCdable"
                  label="분류코드"
                  name="pipeTypeCd"
                  v-model="data.pipeTypeCd">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 유체의명칭 또는 구분 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="유체의명칭 또는 구분"
                  name="pipeName"
                  v-model="data.pipeName">
                </c-text>
              </div>
              <div class="col-4">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-4">
                <!-- 설계압력(MPa) -->
                <c-text
                  suffix="MPa"
                  :editable="editable"
                  label="설계압력(MPa)"
                  name="designPress"
                  v-model="data.designPress">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 설계온도(℃) -->
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="설계온도(℃)"
                  name="designTemp"
                  v-model="data.designTemp">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 배관재질 -->
                <c-text
                  :editable="editable"
                  label="배관재질"
                  name="pipeMaterial"
                  v-model="data.pipeMaterial">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 개스킷 재질 및 형태 -->
                <c-text
                  :editable="editable"
                  label="개스킷 재질 및 형태"
                  name="gasketMaterial"
                  v-model="data.gasketMaterial">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 개스킷 재질 및 상세 -->
                <c-text
                  :editable="editable"
                  label="개스킷 재질 및 상세"
                  name="gasketMaterial"
                  v-model="data.gasketMaterial">
                </c-text>
              </div>
              <div class="col-2">
                <!-- 비파괴검사율(%) -->
                <c-text
                  suffix="%"
                  :editable="editable"
                  label="비파괴검사율(%)"
                  name="noneDestructPercent"
                  v-model="data.noneDestructPercent">
                </c-text>
              </div>
              <div class="col-2">
                <!-- 후열처리여부 -->
                <c-text
                  :editable="editable"
                  label="후열처리여부"
                  name="afterHeatTreatFlag"
                  v-model="data.afterHeatTreatFlag">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div> -->
              <div class="col-12">
                <!-- 비고 -->
                <c-textarea
                  :editable="editable"
                  label="비고"
                  name="remarks"
                  v-model="data.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 20px !important;">
          <!-- 첨부파일 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="첨부파일">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 배관 및 개스킷 별 화학물질 목록 -->
          <c-table
            ref="substanceTable"
            title="배관 및 개스킷 별 화학물질 목록"
            tableId="substanceTable"
            :columns="grid2.columns"
            :data="grid2.data"
            :columnSetting="false"
            selection="multiple"
            rowKey="psiPipeGasketSubstancesId"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="250px"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <!-- 환경부-화학물질정보 연동 추가 -->
                <c-btn label="환경부-화학물질정보 연동 추가" v-if="editable" icon="add" @btnClicked="addApiRow" />
                      <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
                      <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props,col }">
              <template>
                <q-chip
                  v-if="col.name==='msdslink' && props.row.mttrid != ''"
                  color="blue"
                  :clickable="true"
                  @click.stop="() => msdslink(props.row)"
                  text-color="white">
                  MSDS
                </q-chip>
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'piping-gaskets-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiPipeGasketId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PSI_PIPINGGASKETS',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      selectedpsiPipeGasketId: null,
      grid: {
        columns: [
          {
            name: 'pipeTypeCd',
            field: 'pipeTypeCd',
            // 분류코드
            label: '분류코드',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'pipeName',
            field: 'pipeName',
            // 유체의명칭 또는 구분
            label: '유체의명칭 또는 구분',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          
        ],
        data: [],
      },
      data: {
        psiPipeGasketId: '',  // 배관및개스킷 일련번호
        plantCd: '',  // 사업장코드
        pipeTypeCd: '',  // 분류코드
        pipeName: '',  // 유체의명칭또는구분
        designPress: '',  // 설계압력(Mpa)
        designTemp: '',  // 설계온도(℃)
        pipeMaterial: '',  // 배관재질
        gasketMaterial: '',  // 개스킷 재질 및 형태
        noneDestructPercent: '',  // 비파괴검사율
        afterHeatTreatFlag: '',  // 후열처리여부
        remarks: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: transactionConfig.psi.pfi.pipegasket.insert.url,
      mappingType: 'POST',
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      sublistUrl: '',
      subsaveUrl: '',
      subdeleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      deletable: false,
      isRevision: false,
      pipeTypeCdable: true,
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      saveCallData: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.psi.pfi.pipegasket.list.url;
      this.detailUrl = selectConfig.psi.pfi.pipegasket.get.url;
      this.insertUrl = transactionConfig.psi.pfi.pipegasket.insert.url;
      this.updateUrl = transactionConfig.psi.pfi.pipegasket.update.url;
      this.deleteUrl = transactionConfig.psi.pfi.pipegasket.delete.url;

      this.sublistUrl = selectConfig.psi.pfi.pipegasket.substance.list.url;
      this.subsaveUrl = transactionConfig.psi.pfi.pipegasket.substance.save.url;
      this.subdeleteUrl = transactionConfig.psi.pfi.pipegasket.substance.delete.url;
      this.revlistUrl = selectConfig.psi.pfi.pipegasket.list.url + '/revs';
      this.getData();
      this.setHeader();
    },
    getList(_plantCd, _pipeTypeCd) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {plantCd: _plantCd, pipeTypeCd: _pipeTypeCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    setHeader() {
      this.grid2.columns = [
      {
            name: 'casNo',
            field: 'casNo',
            // CAS No.
            label: 'CAS No.',
            align: 'center',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'chemNmKr',
            field: 'chemNmKr',
            // 화학물질명(KOR)
            label: '화학물질명(KOR)',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            // 화학물질명(ENG)
            label: '화학물질명(ENG)',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'msdslink',
            field: 'msdslink',
            label: 'MSDS',
            type: 'custom',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            // 산업안전보건공단에서 제공하는 MSDS정보
            helpcomment: this.$language('산업안전보건공단에서 제공하는 MSDS정보')
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            // 분자식
            label: '분자식',
            align: 'center',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            // 함유량(%)
            label: '함유량(%)',
            align: 'center',
            child: [
              {
                name: 'limitHigh',
                field: 'limitHigh',
                // 상한
                label: '상한',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'limitLow',
                field: 'limitLow',
                // 하한
                label: '하한',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'limitRepval',
                field: 'limitRepval',
                // 대표값
                label: '대표값',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
            ]
          },
          {
            // 물질정보
            label: '물질정보',
            align: 'center',
            child: [
              {
                name: 'exposedBasis',
                field: 'exposedBasis',
                // 노출기준
                label: '노출기준',
                align: 'center',
                style: 'width:110px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'toxicity',
                field: 'toxicity',
                // 독성치
                label: '독성치',
                align: 'center',
                style: 'width:120px',
                type: 'text',
                sortable: false,
              },
            ]
          },
      ]
    },
    getSubList(row) {
      this.$http.type = 'GET';
      this.$http.url = this.$format(this.sublistUrl, row.psiPipeGasketId);
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    addData() {
      this.popupParam.psiPipeGasketId = '';
      this.deletable = false;
      this.pipeTypeCdable = false;
      this.rowRemoveSelect();
      this.grid2.data = [];
      this.data = {
        psiPipeGasketId: '',  // 배관및개스킷 일련번호
        plantCd: '',  // 사업장코드
        pipeTypeCd: (this.grid.data.length > 0 ? this.grid.data[0].pipeTypeCd : ''),  // 분류코드
        pipeName: '',  // 유체의명칭또는구분
        designPress: '',  // 설계압력(Mpa)
        designTemp: '',  // 설계온도(℃)
        pipeMaterial: '',  // 배관재질
        gasketMaterial: '',  // 개스킷 재질 및 형태
        noneDestructPercent: '',  // 비파괴검사율
        afterHeatTreatFlag: '',  // 후열처리여부
        remarks: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      }
    },
    rowRemoveSelect() {
      this.deletable = false
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiPipeGasketId) {
        this.getDetail(this.popupParam.psiPipeGasketId, true);
        this.$set(this.attachInfo, 'taskKey', this.popupParam.psiPipeGasketId)
        this.pipeTypeCdable = false;
      } else {
        this.pipeTypeCdable = true;
      }
    },
    getDetail(_psiPipeGasketId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiPipeGasketId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.getList(_result.data.plantCd, _result.data.pipeTypeCd);
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiPipeGasketId == this.popupParam.psiPipeGasketId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
        this.deletable = true;
        this.getSubList({psiPipeGasketId:_result.data.psiPipeGasketId});
      },);
    },
    rowClick(row) {
      this.$http.url = this.$format(this.detailUrl, row.psiPipeGasketId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.deletable = true;
        this.data = _result.data;
        this.popupParam.psiPipeGasketId = _result.data.psiPipeGasketId;
        this.getRevList(_result.data.groupId);
        this.getSubList(row);
        this.pipeTypeCdable = false;
        this.selectedpsiPipeGasketId = _result.data.psiPipeGasketId;
      },);
    },
    saveData() {
      if (this.popupParam.psiPipeGasketId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';  
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiPipeGasketId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowRemoveSelect();
            if (this.grid.data.length == 1) {
              this.$emit('closePopup');
            } else {
              this.getList(this.data.plantCd, this.data.pipeTypeCd);
            }
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      this.popupParam.psiPipeGasketId = result.data.psiPipeGasketId
      this.$set(this.attachInfo, 'taskKey', result.data.psiPipeGasketId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.saveSubstance();
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.grid2.data.splice(0, 0, {
        editFlag: 'C',
        psiPipeGasketId: this.popupParam.psiPipeGasketId,  // 배관및개스킷 일련번호
        psiPipeGasketSubstancesId: uid(),  // 조성품 일련번호
        casNo: '',  // CAS No.
        chemNmKr: '',  // 화학물질명(KOR)
        chemNmEn: '',  // 화학물질명(ENG)
        limitHigh: '',  // 함유량 상한(%)
        limitLow: '',  // 함유량 하한(%)
        limitRepval: '',  // 함유량 대표값(%)
        molecularFormula: '',  // 분자식
        exposedBasis: '',  // 노출기준
        toxicity: '',  // 독성치
        mttrid: '',  // 환경부데이터 물질ID
        regUserId: this.$store.getters.user.userId,
      })
    },
    addApiRow() {
      this.popupOptions.target = () => import(`${'@/pages/common/openapi/materialApiPop.vue'}`);
      this.popupOptions.title = '환경부-화학물질정보 연동 검색'; // 환경부-화학물질정보 연동 검색
      this.popupOptions.width = '50%';
      this.popupOptions.param = {
        type: 'multiple'
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    msdslink(row) {
      window.open('https://msds.kosha.or.kr/MSDSInfo/kcic/msdsdetail.do?chem_id='+row.mttrid+'&viewType=msds','msdspop','width=1280, height=768, menubar=no, status=no, toolbar=no');
    },
    closePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data.length !== undefined) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.grid2.data, { casNo: item.casno }) === -1) {
            this.grid2.data.splice(0, 0, {
              editFlag: 'C',
              psiPipeGasketId: this.popupParam.psiPipeGasketId,  // 배관및개스킷 일련번호
              psiPipeGasketSubstancesId: uid(),  // 조성품 일련번호
              casNo: item.casno ? item.casno : '',
              chemNmKr: item.mttrnmkor ? item.mttrnmkor : '',
              chemNmEn: item.mttrnmeng ? item.mttrnmeng : '',
              limitHigh: '',  // 함유량 상한(%)
              limitLow: '',  // 함유량 하한(%)
              limitRepval: '',  // 함유량 대표값(%)
              molecularFormula: item.molecform ? item.molecform : '',
              exposedBasis: '',  // 노출기준
              toxicity: '',  // 독성치
              mttrid: item.chemId ? item.chemId : '',  // 환경부데이터 물질ID
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    saveSubstance() {
      let checkItem = ['chemNmKr']
      let isConti = true;
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [화학물질명]',  // 필수 입력값을 입력해 주세요. [화학물질명]
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = [];
        if (this.isRevision) {
          saveData = this.grid2.data;
          this.$_.forEach(saveData, item => {
            item.editFlag = 'C';
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
            item.psiPipeGasketId = this.popupParam.psiPipeGasketId;
          });
        } else {
          saveData = this.grid2.data.filter( x => {
            return x.editFlag == 'C' || x.editFlag == 'U'
          });
          this.$_.forEach(saveData, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
            item.psiPipeGasketId = this.popupParam.psiPipeGasketId;
          });
        }
        if(saveData.length > 0) {
          this.$http.url = this.subsaveUrl;
          this.$http.type = 'POST';
          this.$http.param = saveData;
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', 
              message: '저장되었습니다.', 
              type: 'success', // success / info / warning / error
            });
            this.getData();
          });
        } else {
          this.getData();
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '저장되었습니다.', 
            type: 'success', // success / info / warning / error
          });
        }
      }
    },
    removeRow() {
      let selectData = this.$refs['substanceTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.subdeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getSubList({psiPipeGasketId: this.popupParam.psiPipeGasketId});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['substanceTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.psiPipeGasketId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.psiPipeGasketId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.getDetail(row, true);
        this.$set(this.attachInfo, 'taskKey', row)
      }
    },
  }
};
</script>
